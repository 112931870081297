import { useRef } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ id, children }) => {
  const element = useRef(document.getElementById(id) || document.createElement('div'));
  const current = element.current;
  return createPortal(children, current);
};

export default Portal;
