import types from 'Store/Actions/types';

const selectHashtag = (payload) => {
  return { type: types.NAVBAR_SELECT_HASHTAG, payload };
};

const setNavTitle = (payload) => {
  return { type: types.NAVBAR_SET_TITLE, payload };
};

const setNavClass = (payload) => {
  return { type: types.NAVBAR_SET_CLASS, payload };
};

const setImageBackground = (payload) => {
  return { type: types.NAVBAR_SET_IMAGE_BACKGROUND, payload };
};

const resetNavBar = () => {
  return { type: types.NAVBAR_RESET };
};

export default {
  selectHashtag,
  setNavTitle,
  setNavClass,
  setImageBackground,
  resetNavBar,
};
