import axios from 'axios';

import { baseUrl } from 'Constants/Env';

const api = axios.create({
  baseURL: baseUrl,
});

const errorHandler = ({ data }) => {
  return data;
};

export const http = {
  post: (url, data, options) => api.post(url, data, options).then(errorHandler),
  get: (url, options) => api.get(url, options).then(errorHandler),
  put: (url, data, options) => api.put(url, data, options).then(errorHandler),
  patch: (url, data, options) => api.patch(url, data, options).then(errorHandler),
  delete: (url, options) => api.delete(url, options).then(errorHandler),
  generateCancelController() {
    return axios.CancelToken.source();
  },
  isRequestCanceled(e) {
    return axios.isCancel(e);
  },
};
