import { env } from 'Constants/Env';
import type { Enum } from 'types/enum-types';

const idpKeys = {
  int: '3_Ztq8qmq4SdZtu18QYQsnHrEHioaLUzCEu_ZucKs5CExxTnlNoAjmY2-Zc_Qitqe3',
  pre: '3_udp0Psgh69ubkEcHj5h1EBjC1-lNxqfaiPxehTlPFFoGhd56AkwyQ_vfPHSFqhag',
  prod: '3_FK4xnivd9VRZnEnzofgXraiihhak8FdXU7OnwXjitTQM1ccZSkm_oKiAz9Wk-tqO',
};

const idpUrls = {
  int: 'https://idpassets-int.uefa.com/idp.js',
  pre: 'https://idpassets-pre.uefa.com/idp.js',
  prod: 'https://idpassets.uefa.com/idp.js',
};

export const UserEvents = {
  LOGIN: 'login',
  REGISTER: 'register',
  LOGOUT: 'logout',
  USERCHANGE: 'user-change',
  ERROR: 'error',
  REQUESTCLOSE: 'request-close',
  FAV_CLUB_UPDATED: 'profile-fav-club-updated',
  FAV_TEAM_UPDATED: 'profile-fav-national-team-updated',
} as const;
export type UserEvents = Enum<typeof UserEvents>;

export const LoginSources = {
  HEADER: 'Header',
  CONTENT: 'Content Section',
} as const;
export type LoginSources = Enum<typeof LoginSources>;

export type IdpUser = {
  UID?: string;
  originalUser?: any;
  created?: string;
  profile?: {
    nickname?: string;
    name?: string;
    firstName?: string;
    email?: string;
    thumbnailURL?: string;
    lastName?: string;
    age?: number;
    birthDay?: number;
    birthMonth?: number;
    birthYear?: number;
    country?: string;
    locale?: string;
  };
  data?: {
    subscriptions?: {
      subscribeToAll?: boolean;
    };
    ticketing?: {
      ticketsDeliveryEmail?: string;
    };
    favourites: {
      club?: string;
      clubsFollowed?: string;
      competitions?: {
        [comp: string]: {
          club?: string;
        };
      };
      nationalTeamsFollowed?: string;
      nationalTeam?: string;
      womenNationalTeam?: string;
    };
    accounts: {
      acquisition: {
        regRedirectURL: 'https://www.uefa.com/uefachampionsleague/clubs/50051--real-madrid/';
        regSource: 'UCL Web';
        regDate: '2022-05-16T15:03:35.238Z';
        regCampaign: 'https://www.uefa.com/uefachampionsleague/clubs/50051--real-madrid/';
      };
    };
  };
  subscriptions?: {
    newsletters?: {
      [newsletterId: string]: {
        email?: {
          isSubscribed: boolean;
          lastUpdatedSubscriptionState?: string;
        };
      };
    };
    sponsors?: {
      all?: {
        email?: {
          isSubscribed: boolean;
          lastUpdatedSubscriptionState?: string;
        };
      };
    };
  };
};

export type FavouriteClubUpdateEvent = {
  competition?: 'ucl' | 'uel' | 'men' | 'women';
  label?: string;
  teamId?: string;
  value?: string;
  englishLabel?: string;
};

export interface IdpEventEmitter {
  on(event: string, callback: (...args: unknown[]) => void): IdpEventEmitter;
  once(event: string, callback: (...args: unknown[]) => void): IdpEventEmitter;
}

export type IdpResponse = {
  status: string;
  errorCode: number;
  errorMessage?: string;
};

export interface IdpManager {
  init(config: { apiKey: string; locale: string }): void;
  onReady(): Promise<IdpUser>;
  isLogged(): boolean;
  getCurrentUser(): Promise<IdpUser>;
  getJwtToken(): Promise<string>;
  logout(): Promise<void>;
  getEventEmitter(): IdpEventEmitter;
  showSocialRegister(options: { containerID?: string; redirectUrl?: string }): IdpEventEmitter;
  showLogin(options: { containerID?: string; redirectUrl?: string }): IdpEventEmitter;
  showProfile(options: { modal?: boolean; selector?: string; screen?: string }): IdpEventEmitter;
  showRegister(options: { containerID?: string; redirectUrl?: string }): IdpEventEmitter;
  showLiteRegistration(options: { selector: string; newsletterId?: string; campaign?: string }): IdpEventEmitter;
  showMinimalLiteRegistration(options: {
    selector: string;
    newsletterId?: string;
    campaign?: string;
    redirectUrl?: string;
  }): IdpEventEmitter;
  showScreen(options: unknown): IdpEventEmitter;
  getFavouriteTeamCode(): Promise<string>;
  setFavouriteTeam(team: string): Promise<IdpResponse>;
  removeFavouriteTeam(): Promise<IdpResponse>;
  getFavouriteWomenTeamCode(): Promise<string>;
  setFavouriteWomenTeam(team: string): Promise<IdpResponse>;
  removeFavouriteWomenTeam(): Promise<IdpResponse>;
  getFavouriteClubId(comp?: string): Promise<string | { [comp: string]: { club: string } }>;
  setFavouriteClub(comp: string, club?: string): Promise<IdpResponse>;
  getFollowedClubIds(): Promise<string[]>;
  getFollowedTeamIds(): Promise<string[]>;
}

declare global {
  interface Window {
    IdpManager?: IdpManager;
  }
}

let idpPromise: Promise<IdpManager> = null;

class IdpClient {
  static appendComponentScript() {
    return new Promise<void>((resolve) => {
      const url = idpUrls[env];
      let script = document.querySelector<HTMLScriptElement>(`script[src*="${url}"]`);

      if (script) return resolve();
      script = document.createElement('script');

      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => resolve();
      document.head.appendChild(script);
    });
  }

  static waitIdp() {
    return new Promise<IdpManager>((resolve) => {
      if (window.IdpManager) {
        resolve(window.IdpManager);
        return;
      }

      const interval = setInterval(() => {
        if (window.IdpManager) {
          clearInterval(interval);
          resolve(window.IdpManager);
        }
      }, 2000);
    });
  }

  static init(locale) {
    idpPromise ??= (async () => {
      await this.appendComponentScript();
      const idp = await this.waitIdp();

      const apiKey = idpKeys[env];
      idp.init({ apiKey, locale });

      await idp.onReady();

      return idp;
    })();

    return idpPromise;
  }
}

export default IdpClient;
