export const navMenuLinks = [
  { title: 'navMenu.footballNearYou', href: '/football-near-you' },
  { title: 'navMenu.supporters', href: '/supporters' },
  { title: 'navMenu.partners', href: '/partners' },
  { title: 'navMenu.about', href: '/about' },
];

export const socialLinks = [
  { title: 'Instagram', href: 'https://www.instagram.com/weplaystrong' },
  { title: 'YouTube', href: 'https://www.youtube.com/weplaystrong' },
  { title: 'TikTok', href: 'https://www.tiktok.com/@weplaystrong' },
  { title: 'Twitter', href: 'https://www.twitter.com/weplaystrong' },
  { title: 'Facebook', href: 'https://www.facebook.com/weplaystrong' },
  { title: 'Giphy', href: 'https://www.giphy.com/weplaystrong' },
];

export const FACEBOOK_APP_ID = '3637676002952740';
export const FACEBOOK_CLIENT_SECRET = '9cfbc485ab6261388045ab077f82eaa9';
export const CAROUSEL_HASHTAG = 'PlayAnywhere';

export const MAX_ARTICLES_DISPLAYED = 8;

export const footballNearYou = [
  { country: 'Albania', href: 'https://fshf.org/kontakt/' },
  {
    country: 'Andorra',
    href: 'https://www.facebook.com/Fedandfut',
  },
  { country: 'Armenia', href: 'https://www.ffa.am/en/representative' },
  { country: 'Austria', href: 'https://www.oefb.at/oefb/News/Vereine' },
  {
    country: 'Azerbaijan',
    href: 'https://www.affa.az/index.php/about-affa/contacts/1624',
  },
  { country: 'Belarus', href: 'https://abff.by/mass_football/contacts' },
  { country: 'Belgium', href: 'https://www.rbfa.be/fr/football-feminin-rbfa' },
  {
    country: 'Bosnia and Herzegovina',
    href: 'https://www.nfsbih.ba/contact/',
  },
  {
    country: 'Bulgaria',
    href: 'https://bfunion.bg/Grassroots-%D0%96%D0%B5%D0%BD%D0%B8-%D0%98-%D0%94%D0%B5%D0%B2%D0%BE%D0%B9%D0%BA%D0%B8-%D0%9A%D0%BB%D1%83%D0%B1%D0%BE%D0%B2%D0%B5',
  },
  {
    country: 'Croatia',
    href: 'https://hns-cff.hr/en/hns/county-football-associations/',
  },
  {
    country: 'Cyprus',
    href: 'https://www.facebook.com/cyprusfaofficial/about',
  },
  { country: 'Czech Republic', href: 'https://mujfotbal.fotbal.cz/' },
  {
    country: 'Denmark',
    href: 'https://www.dbu.dk/boern-og-unge/talentudvikling/talentudvikling-piger/',
  },
  { country: 'England', href: 'https://www.thefa.com/womens-girls-football' },
  {
    country: 'Estonia',
    href: 'https://www.facebook.com/EestiNaisteJalgpall/',
  },
  { country: 'Faroe Islands', href: 'https://www.fsf.fo' },
  { country: 'Finland', href: 'https://www.palloliitto.fi/maajoukkueet/tytot' },
  {
    country: 'France',
    href: 'https://www.fff.fr/4-les-joueurs-et-joueuses/index.html',
  },
  { country: 'Georgia', href: 'https://gff.ge/ge/grassroots/get-involved' },
  { country: 'Germany', href: 'https://www.dfb.de/dfb-maedels/start/' },
  { country: 'Gibraltar', href: 'https://www.gibraltarfa.com/women' },
  { country: 'Greece', href: 'https://www.epo.gr' },
  { country: 'Hungary', href: 'https://www.mlsz.hu/kapcsolat' },
  {
    country: 'Iceland',
    href: 'https://www.ksi.is/fraedsla/komdu-i-fotbolta/komdu-i-fotbolta-med-mola/',
  },
  {
    country: 'Israel',
    href: 'https://www.football.org.il/?catid=%7B2156F403-9B41-467D-A1B2-983C617C58B6%7D',
  },
  {
    country: 'Italy',
    href: 'https://www.figc.it/en/figc/mission-and-governance/womens-football/',
  },
  { country: 'Kazakhstan', href: 'https://kff.kz/en/regions' },
  { country: 'Kosovo', href: 'https://www.ffk-kosova.com/en/contact/' },
  { country: 'Latvia', href: 'https://lff.lv/grassroots/we-play-strong/' },
  {
    country: 'Liechtenstein',
    href: 'https://www.lfv.li/frauenfussball/stuetzpunkte-vereine/kontakte',
  },
  { country: 'Lithuania', href: 'https://lff.lt/dalyvauk/moterys-uz-futbola/' },
  {
    country: 'Luxembourg',
    href: 'https://www.flf.lu/La-FLF/Administration-federale.aspx',
  },
  {
    country: 'Malta',
    href: 'https://www.mfa.com.mt/en/the-mfa/themfa/13/womens-football.htm',
  },
  { country: 'Moldova', href: 'https://www.facebook.com/fmfmoldova/about' },
  {
    country: 'Montenegro',
    href: 'https://www.facebook.com/FudbalskiSavez/about/?ref=page_internal',
  },
  {
    country: 'Netherlands',
    href: 'https://www.knvb.nl/ontdek-voetbal/ga-voetballen/vind-een-club',
  },
  { country: 'North Macedonia', href: 'https://ffm.mk/en/contact' },
  {
    country: 'Northern Ireland',
    href: 'https://www.irishfa.com/irish-fa-foundation/grassroots-and-youth-football/girls-and-womens-football',
  },
  { country: 'Norway', href: 'https://www.fotball.no/barn-og-ungdom/' },
  { country: 'Poland', href: 'https://www.laczynaspilka.pl/kontakt.html' },
  {
    country: 'Portugal',
    href: 'https://www.fpf.pt/pt/FooterMenu/Contactos/Contactos',
  },
  {
    country: 'Republic of Ireland',
    href: 'https://www.fai.ie/domestic/fai-womens/aviva-soccer-sisters',
  },
  { country: 'Romania', href: 'https://www.frf.ro/despre-frf/contact/' },
  { country: 'Russia', href: 'https://rfs.ru/subject/1/contacts' },
  {
    country: 'San Marino',
    href: 'https://www.fsgc.sm/federazione__trashed/contatti/',
  },
  {
    country: 'Scotland',
    href: 'https://www.scottishfa.co.uk/football-development/participation/girls-womens-football/',
  },
  { country: 'Serbia', href: 'https://www.facebook.com/FudbalskiSavezSrbije' },
  {
    country: 'Slovakia',
    href: 'https://www.futbalsfz.sk/mladez-a-rozvoj/zensky-futbal',
  },
  { country: 'Slovenia', href: 'https://www.nzs.si/zenski-nogomet/' },
  { country: 'Spain', href: 'https://www.facebook.com/SeFutbol' },
  { country: 'Sweden', href: 'https://svenskfotboll.se/landslag/damer/' },
  {
    country: 'Switzerland',
    href: 'https://www.football.ch/sfv/juniorinnen-und-frauenfussball/juniorinnen-breitenfussball.aspx',
  },
  { country: 'Turkey', href: 'https://www.tff.org/Default.aspx?pageID=450' },
  {
    country: 'Ukraine',
    href: 'https://en.uaf.ua/about-ffu/regional-federations',
  },
  {
    country: 'Wales',
    href: 'https://www.fawtrust.cymru/grassroots/girlsfootball/',
  },
];

export const pageTypes = {
  hub: 'hub',
  article: 'article',
  landing: 'landing',
};
