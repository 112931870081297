import './App.scss';

import { Provider } from 'react-redux';

import Main from './Pages/Main/Main';
import { WpsContext, store } from './Store';

import 'i18n';

function App() {
  return (
    <Provider store={store} context={WpsContext}>
      <Main />
    </Provider>
  );
}

export default App;
