import { detect } from 'detect-browser';

import { baseUrl } from 'Constants/Env';

export function updateDataLayer(pageName, pageAmendment, type) {
  if (window.dataLayer) {
    const completePageName = pageAmendment ? `/twps${pageName}/${pageAmendment}/` : `/twps${pageName}/`;
    window.dataLayer.push({
      event: 'Page Meta',
      pageSubType: 'twps',
      pageName: completePageName,
      pageType: type,
    });
    window.dataLayer.push({
      event: 'virtPath',
      virtPath: completePageName,
    });
  }
}

const parser = new DOMParser();
export function parseHtml(html = '', type: DOMParserSupportedType = 'text/html', getBody = false) {
  const parsed = parser.parseFromString(html, type).body;

  return getBody ? parsed : (parsed.firstChild as HTMLElement);
}

export function stripHtml(html = '') {
  if (!html) return '';
  const doc = parseHtml(`<div>${html}`);
  return doc.innerText ?? doc.textContent ?? '';
}

export function processHashtags(hashtagsArr: EditorialContent) {
  if (!hashtagsArr?.hashtags?.length) return '';
  return hashtagsArr?.hashtags[hashtagsArr.hashtags.length - 1].charAt(0) === '#'
    ? hashtagsArr?.hashtags[hashtagsArr.hashtags.length - 1]
    : `#${hashtagsArr?.hashtags[hashtagsArr.hashtags.length - 1]}`;
}

export const slugSeparator = '--';
export const convertToSlug = (id: string, text: string) =>
  text
    ? `${id}${slugSeparator}${text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')}`
    : id;

export const getIdFromPath = (url = location.pathname) => {
  return new RegExp(`\\/([\\d\\w-]+)${slugSeparator}`).exec(url)?.[1];
};

export const resolvePathUrl = (url: string, format: string) => {
  if (!url?.includes('/')) return null;

  const arr = url.split('/');
  arr.splice(arr.length - 1, 0, 'format', format);
  return `${baseUrl}${arr.join('/')}`;
};

export type MediaImage = {
  id: string;
  credits?: string;
  description?: string;
  title?: string;
  image?: {
    custom?: Record<string, string>;
    wide?: { w1: string; w2?: string; w3?: string; w4?: string };
    square?: { s1: string; s2?: string; s3?: string; s4?: string };
    portrait?: { p1: string; p2?: string; p3?: string };
    ultraWide?: { ultrawide: string };
    free?: { f1: string; f2?: string; f3?: string; f4?: string };
  };
};

export const getImage = (mediaItem): MediaImage => {
  const figure = mediaItem?.figure?.[0];

  if (!figure?.img) return null;

  const photo: MediaImage = {
    id: undefined,
    credits: figure.figcredits,
    description: figure.figcaption,
    title: figure.figcaption,
    image: {},
  };

  figure.img.forEach((i) => {
    const format = i['emk-softCrop'];

    if (format?.endsWith('px-Custom')) {
      const customFormat = i['emk-softCrop'].replace('px-Custom', '');
      photo.image.custom = {
        [customFormat]: `${baseUrl}${i.src}`,
      };
    }

    switch (i['emk-softCrop']) {
      case 'Wide':
        photo.image.wide = {
          w1: resolvePathUrl(i.src, 'wide1'),
          w2: resolvePathUrl(i.src, 'wide2'),
          w3: resolvePathUrl(i.src, 'wide3'),
          w4: resolvePathUrl(i.src, 'wide4'),
        };
        photo.id = i['data-id'];
        break;
      case 'Square':
        photo.image.square = {
          s1: resolvePathUrl(i.src, 'square1'),
          s2: resolvePathUrl(i.src, 'square2'),
          s3: resolvePathUrl(i.src, 'square3'),
          s4: resolvePathUrl(i.src, 'square4'),
        };
        break;
      case 'Portrait':
        photo.image.portrait = {
          p1: resolvePathUrl(i.src, 'portrait1'),
          p2: resolvePathUrl(i.src, 'portrait2'),
          p3: resolvePathUrl(i.src, 'portrait3'),
        };
        break;
      case 'Ultrawide':
        photo.image.ultraWide = {
          ultrawide: resolvePathUrl(i.src, 'ultrawide'),
        };
        break;
      case 'Free':
        photo.image.free = {
          f1: resolvePathUrl(i.src, 'free1'),
          f2: resolvePathUrl(i.src, 'free2'),
          f3: resolvePathUrl(i.src, 'free3'),
          f4: resolvePathUrl(i.src, 'free4'),
        };
        break;
    }
  });

  return photo;
};

const getAnswers = (item) => {
  const answers = item['_json'].filter((x) => x['_type'] === 'ANSWERS')?.[0];
  return answers['_json']?.map((answer) => ({
    text: answer._text,
    id: answer['_attributes']?.['data-answer-id'],
  }));
};

export const getPoll = (item) => {
  const poll = { pollId: null, question: null, answers: null };

  poll.question = item['_json'].filter((x) => x['_type'] === 'QUESTION')?.[0]?._text;
  poll.answers = getAnswers(item);
  poll.pollId = item['_attributes']?.['data-poll-id'];

  return poll;
};

const getDuration = (mediaInfo) => {
  const result = parseInt(mediaInfo?.duration);
  return isNaN(result) ? 0 : result;
};

const getByType = (arr, type) => arr?.find((o) => o._type === type || o.type === type);

const mapByType = (el) => {
  if (!el) return;
  switch (el._type) {
    case 'PHOTO':
      return getImage(el);
    case 'POLL':
      return getPoll(el);
    default:
      return el;
  }
};

export const mapEditorialContent = (data: any, nodes?: { [id: string]: string }, isList = false) => {
  if (!data) return {};

  const type = data.sys.type;
  const document = data.files['content.json']?.data.document;
  const mediaInfo = data.attributes.mediaInfo;
  const hideFromWeb = data.attributes.hideFromWeb;

  const resourceUrl = getByType(data.attributes.related_resources, 'pdf')?.resourceUrl;
  const promoUrl = type === 'promo' ? document?.content?.find((o) => o._type === 'LINK')?._attributes?.href : null;

  const targetUrl = promoUrl ?? (resourceUrl && `${baseUrl}${resourceUrl}`);

  const alternateHeadline = getByType(document?.headgroup, 'ALTERNATE-HEADLINE')?._html;
  const headline = ((isList && alternateHeadline) || getByType(document?.headgroup, 'HEADLINE')?._html) ?? data.title;

  const alternateSummary = getByType(document?._elements, 'ALTERNATE-SUMMARY')?._html;
  const summary = (isList && alternateSummary) || getByType(document?._elements, 'SUMMARY')?._html;

  const tag = data.attributes?.tagName;
  const hashtags = data.attributes?.hashtags;

  const photos =
    type === 'gallery'
      ? document?.content
          ?.filter((o) => o._type === 'PHOTO')
          .map(getImage)
          .filter(Boolean)
      : null;
  const body =
    type === 'article' || type === 'card' || type === 'prizehubcard' || type === 'poll'
      ? document?.content?.map(mapByType).filter(Boolean)
      : null;

  let mainMedia = mapByType(document?.mediagroup?.[0]);
  let imageUrl = mainMedia?.image?.wide?.w1;

  const topcontent = document?.linkgroup?.find(
    (e) => e._attributes?.['emk-class'] && e._attributes['emk-class'] !== 'sourceCorrelation',
  );

  const media = topcontent?._attributes?.['data-id'] && nodes?.[topcontent._attributes['data-id']];

  const podcastUrl = document?.content?.find((o) => o._type === 'LINK' && o._attributes['emk-type'] === 'podcast-link')
    ?._attributes?.href;

  if (media) {
    mainMedia = mapEditorialContent(media);
    imageUrl = imageUrl ?? mainMedia?.image?.wide?.w1;
  }

  let sponsor = null;
  let sponsors = [];
  if (data?.attributes?.sponsor || data?.attributes?.sponsors) {
    sponsors = getSponsor(data.attributes);
    sponsor = sponsors?.[0] ?? [];
  }

  return {
    id: data.id,
    hideFromWeb,
    type,
    title: stripHtml(headline),
    headline,
    targetUrl,
    htmlDescription: summary,
    description: stripHtml(summary),
    body,
    mediaParts: mediaInfo,
    imageUrl,
    mainMedia,
    photos,
    duration: getDuration(mediaInfo),
    width: mediaInfo?.width,
    height: mediaInfo?.height,
    classification: data?.attributes?.main,
    hlsStreamUrl: mediaInfo?.mediaUrl,
    credits: mediaInfo?.credit,
    comment: mediaInfo?.caption,
    liveblogData: data?.attributes?.liveblogData,
    publicationDate: data?.attributes?.firstPublicationDate,
    newsDate: data?.attributes?.firstPublicationDate,
    lastModificationDate: data?.attributes?.lastPublicationDate,
    externalProduct: data?.attributes?.externalProducts?.[0],
    document,
    sponsor,
    sponsors,
    relations: data?.attributes?.footballEntities,
    podcastUrl,
    tag,
    hashtags,
    authors: data?.authors ?? [],
  };
};

export type EditorialContent = ReturnType<typeof mapEditorialContent>;

const getSponsor = (attributes) => {
  if (attributes.sponsor) {
    return [
      {
        name: attributes.sponsor,
        type: getSponsorType(attributes.sponsor),
        code: getSponsorType(attributes.sponsor),
      },
    ];
  } else {
    return attributes.sponsors.map((x: any) => ({ name: x.sponsorCode, type: x.sponsorCode, code: x.sponsorCode }));
  }
};

const getSponsorType = (sponsor: string) => {
  return sponsor.trim().replaceAll(' ', '-').replaceAll("'", '').replaceAll('.', '').toLowerCase();
};

export function injectScript(src, id: string = crypto.randomUUID()) {
  return new Promise<void>((resolve, reject) => {
    let script: HTMLScriptElement = document.getElementById(id) as HTMLScriptElement;

    if (script) return resolve();

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    script.onload = () => resolve();
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function navigatorShareAvailable() {
  const browser = detect();
  return ['iOS', 'Android OS'].includes(browser.os) && !!window.navigator.share;
}

/**
 * Tracking utilities below
 */

/**
 * Push a generic Event to dataLayer
 */
export function sendEvent(action, category = 'Click', label = 'twps', data = {}, nonInteraction = false) {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    event: 'gaEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    non_interaction: nonInteraction,
    ...data,
  });
}

/**
 * Push Card Impressions
 * @param impressions list of impressions object
 */
export function gtmCardImpression(impressions, nonInteraction = true, eventLabel = null) {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    event: 'Card Impression',
    eventLabel,
    nonInteraction,
    ecommerce: {
      impressions,
    },
  });
}

/**
 * Push Card Click
 * @param list Content Type of the list
 * @param product the product object
 */
export function gtmCardClick(list, product, nonInteraction = false, eventLabel = null) {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    event: 'Card Click',
    eventLabel,
    nonInteraction,
    ecommerce: {
      click: {
        actionField: { list },
        products: [product],
      },
    },
  });
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 * Get data from dataLayer
 * @param key the key to search
 */
export function getFromDataLayer(key) {
  if (!window.dataLayer?.length) return '';

  const dataLayerBlock = window.dataLayer.find((el) => el.hasOwnProperty(key));
  if (!dataLayerBlock) return '';

  return dataLayerBlock[key];
}
