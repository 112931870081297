import types from 'Store/Actions/types';
import type { Sponsor } from 'types/sponsor';

const setLoading = (payload) => {
  return {
    type: types.MAIN_SET_LOADING,
    payload,
  };
};

const setTrendingArticles = (payload) => {
  return {
    type: types.MAIN_SET_TRENDING_ARTICLES,
    payload,
  };
};

const setHashtags = (payload) => {
  return { type: types.MAIN_SET_HASHTAGS, payload };
};

const setArticlePreview = (payload) => {
  return { type: types.MAIN_SET_ARTICLE_PREVIEW, payload };
};

const setSponsors = (payload: Sponsor[]) => {
  return { type: types.MAIN_SET_SPONSORS, payload };
};

export default {
  setLoading,
  setTrendingArticles,
  setHashtags,
  setArticlePreview,
  setSponsors,
};
