import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './FadeInOut.scss';

const FadeInOut = (props) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      unmountOnExit
      in={props.isShown}
      timeout={{ appear: 0, enter: 0, exit: 300 }}
      classNames='wps-fade-in-out'
      appear
      nodeRef={nodeRef}
    >
      <span ref={nodeRef}>{props.children}</span>
    </CSSTransition>
  );
};
FadeInOut.propTypes = {
  isShown: PropTypes.bool,
  children: PropTypes.any,
};

export default FadeInOut;
