import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IdpManager } from 'Services/IdpService';
import IdpClient from 'Services/IdpService';

const useIdp = () => {
  const { i18n } = useTranslation();
  const idpInstance = useRef<IdpManager>();
  const [idpInited, setIdpInited] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (idpInstance.current) {
      idpInstance.current.onReady().then(() => {
        setIdpInited(true);
      });
    } else {
      IdpClient.init(i18n.language).then((idp) => {
        idpInstance.current = idp;
        setIdpInited(true);
      });
    }
  }, [i18n.language]);

  const showProfile = useCallback(() => {
    if (!idpInited) return;
    return idpInstance.current.showProfile({
      modal: true,
    });
  }, [idpInited]);

  useEffect(() => {
    if (!idpInited) return;

    if (idpInstance.current.isLogged()) {
      idpInstance.current.getCurrentUser().then((newUser) => {
        setUser(newUser);
      });
    }

    idpInstance.current.getEventEmitter().on('user-change', ({ user: newUser }) => {
      setUser(newUser);
    });

    idpInstance.current.getEventEmitter().on('login', () => {
      showProfile();
    });
  }, [idpInited, showProfile]);

  const showLogin = (containerID: string) => {
    if (!idpInited) return;
    return idpInstance.current.showLogin({
      containerID,
    });
  };

  const showNewsletter = (selector, newsletterId) => {
    if (!idpInited) return;
    return idpInstance.current.showMinimalLiteRegistration({
      selector,
      newsletterId,
    });
  };

  const getIsSubscribed = useCallback(
    (newsletterId) => {
      const newsletters = user?.subscriptions?.newsletters;
      if (!newsletters) return false;

      if (newsletterId) return !!newsletters[newsletterId]?.email?.isSubscribed;

      return Object.keys(newsletters).some((k) => !!newsletters[k]?.email?.isSubscribed);
    },
    [user],
  );

  return {
    idpInited,
    idp: idpInstance.current,
    user,
    showLogin,
    showProfile,
    showNewsletter,
    getIsSubscribed,
  };
};

export default useIdp;
