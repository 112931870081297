import clsx from 'clsx';
import type { ReactNode } from 'react';
import ReactModal from 'react-modal';

import './Modal.scss';

const Modal = ({
  className = 'generic',
  showModal,
  handleOpenModal,
  handleCloseModal,
  animation,
  children,
}: {
  className?: string;
  showModal: boolean;
  handleOpenModal?: () => void;
  handleCloseModal?: () => void;
  animation?: string;
  children: ReactNode;
}) => {
  const overlayClassNames = clsx({
    [`${className}__modal-overlay`]: className,
    [`ReactModal__Overlay--${animation}`]: animation,
  });

  const classNames = clsx({
    [`${className}__modal`]: className,
  });

  return (
    <ReactModal
      isOpen={showModal}
      closeTimeoutMS={animation ? 500 : 0}
      onAfterOpen={handleOpenModal}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      overlayClassName={overlayClassNames}
      className={classNames}
      ariaHideApp={false}
    >
      <button className='close closeIcon' onClick={handleCloseModal} />
      {children}
    </ReactModal>
  );
};

export default Modal;
