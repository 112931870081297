import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoginModal from '../LoginModal/LoginModal';

import UserIcon from 'Assets/Images/user-icon.svg?react';
import useIdp from 'Hooks/useIdp';
import { sendEvent } from 'Utils/utilities';

import './LoginButton.scss';

const LoginButton = () => {
  const { t } = useTranslation();
  const { user, idpInited, showProfile } = useIdp();
  const [loginVisible, setLoginVisible] = useState(false);

  const username = useMemo(
    () =>
      user?.profile?.nickname ??
      user?.profile?.name ??
      user?.profile?.firstName ??
      user?.profile?.email ??
      t('navMenu.profile'),
    [user, t],
  );

  const handleCloseLogin = () => {
    setLoginVisible(false);
  };

  if (!idpInited) return null;

  return (
    <Fragment>
      {user?.profile ? (
        <div
          onClick={() => {
            showProfile();
            sendEvent('Menu - Access Account');
          }}
          className='login login--logged'
        >
          <UserIcon className='user-icon' />
          <label>{username}</label>
        </div>
      ) : (
        <>
          <div
            onClick={() => {
              setLoginVisible(true);
              sendEvent('Menu - Login');
            }}
            className='login'
          >
            {t('navMenu.login')}
          </div>
          <LoginModal showModal={loginVisible} handleCloseModal={handleCloseLogin} />
        </>
      )}
    </Fragment>
  );
};

export default LoginButton;
