import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoginModal from '../LoginModal/LoginModal';

import ArrowRight from 'Assets/Images/wps-arrow-right.svg?react';
import NewsletterModal from 'Components/NewsletterModal/NewsletterModal';
import useIdp from 'Hooks/useIdp';
import { sendEvent } from 'Utils/utilities';

import './NewsletterInput.scss';

const newsletterName = 'UEFAWomenFootball';

const NewsletterInput = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { user, idpInited, getIsSubscribed, showProfile } = useIdp();

  if (!idpInited) return null;

  const isSubscribed = getIsSubscribed(newsletterName);

  const classNames = clsx({
    newsletter: true,
    [className]: className,
  });

  const handleOpenModal = () => {
    if (user?.profile) {
      isSubscribed ? setShowModal(true) : showProfile();
      sendEvent('Menu - Access Account');
    } else {
      setShowModal(true);
      sendEvent('Newsletter - Open');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    isSubscribed && sendEvent('Newsletter - Close');
  };

  return (
    <div className={classNames}>
      <div onClick={handleOpenModal} className='heading'>
        {t('general.newsletter.label')}
        <span className='submit-arrow'>
          <ArrowRight />
        </span>
      </div>
      {isSubscribed ? (
        <NewsletterModal showModal={showModal} handleCloseModal={handleCloseModal} />
      ) : (
        <LoginModal showModal={showModal && !user} handleCloseModal={handleCloseModal} />
      )}
    </div>
  );
};

export default NewsletterInput;
