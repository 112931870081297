import clsx from 'clsx';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'Components/Modal/Modal';
import useIdp from 'Hooks/useIdp';

import './NewsletterModal.scss';

const newsletterName = 'UEFAWomenFootball';

declare global {
  interface Window {
    showProfile: any;
  }
}

const NewsletterModal = ({ showModal, handleCloseModal }) => {
  const { t } = useTranslation();
  const { showNewsletter, getIsSubscribed, showProfile, user } = useIdp();
  const newsletterContainer = useRef<HTMLDivElement>();
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);

  window.showProfile = showProfile;

  const isSubscribed = getIsSubscribed(newsletterName);

  const onOpenModal = () => {
    if (!newsletterContainer.current) return;
    if (isSubscribed) {
      setAlreadySubscribed(true);
      return;
    }
    setAlreadySubscribed(false);

    showNewsletter(`.newsletter-container`, newsletterName).on('ready', () => {
      const captionContainer = newsletterContainer.current.querySelector('#idp-minimal-lite-registration-page_content');
      const textDiv = document.createElement('div');
      textDiv.innerHTML = t('general.newsletter.text');
      textDiv.classList.add('newsletter__text');
      captionContainer.prepend(textDiv);

      const privacyDiv = document.createElement('div');
      privacyDiv.innerHTML = t('general.newsletter.privacy');
      privacyDiv.classList.add('newsletter__privacy');
      captionContainer.appendChild(privacyDiv);
    });
  };

  const classNames = clsx({
    'newsletter-container': true,
    'newsletter--subscribed': alreadySubscribed,
  });

  return (
    <Modal
      showModal={showModal}
      handleOpenModal={onOpenModal}
      handleCloseModal={handleCloseModal}
      className={'newsletter'}
      animation={'slide-in'}
    >
      <div ref={newsletterContainer} className={classNames}>
        {alreadySubscribed && (
          <Fragment>
            <div className='newsletter__name'>{t('general.newsletter.subscribed.name', { user })}</div>
            <div
              className='newsletter__text'
              dangerouslySetInnerHTML={{ __html: t('general.newsletter.subscribed.text') }}
            ></div>
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default NewsletterModal;
