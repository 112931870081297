import './Button.scss';

import clsx from 'clsx';
import PropTypes from 'prop-types';

const Button = (props) => {
  return (
    <button
      style={props.style}
      type={props.type ? props.type : 'button'}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onTouchStart={props.onTouchStart}
      onTouchEnd={props.onTouchEnd}
      className={clsx(
        {
          'app-button': true,
          'app-button--primary': props.color === 'primary',
          'app-button--secondary': props.color === 'secondary',
          'app-button--tertiary': props.color === 'tertiary',
        },
        props.className,
      )}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  children: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
