import { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router';

const About = lazy(() => import('Pages/About/About'));
const ArticleView = lazy(() => import('Pages/ArticleView/ArticleView'));
const FootballNearYou = lazy(() => import('Pages/FootballNearYou/FootballNearYou'));
const HashtagView = lazy(() => import('Pages/HashtagView/HashtagView'));
const NotFound = lazy(() => import('Pages/NotFound/NotFound'));
const Partners = lazy(() => import('Pages/Partners/Partners'));
const Supporters = lazy(() => import('Pages/Supporters/Supporters'));
const Trending = lazy(() => import('Pages/Trending/Trending'));

export const PublicRoutes = {
  trending: '/trending',
  footballNearYou: '/football-near-you',
  supporters: '/supporters',
  partners: '/partners',
  about: '/about',
  articleView: '/article-view',
  hashtagView: '/hashtag-view',
};

export const routes = (
  <Switch>
    <Redirect exact from='/' to={PublicRoutes.trending} />
    <Route path={PublicRoutes.trending} exact component={Trending} />
    <Route path={PublicRoutes.about} exact component={About} />
    <Route path={PublicRoutes.footballNearYou} exact component={FootballNearYou} />
    <Route path={PublicRoutes.partners} exact component={Partners} />
    <Route path={PublicRoutes.supporters} exact component={Supporters} />
    <Route path={`${PublicRoutes.articleView}/:articleId`} exact component={ArticleView} />
    <Route path={PublicRoutes.articleView} component={ArticleView} />
    <Route path={`${PublicRoutes.hashtagView}/:hashtag`} exact component={HashtagView} />
    <Route path={PublicRoutes.hashtagView} component={HashtagView} />
    <Route component={NotFound} />
  </Switch>
);
