import './MenuDrawer.scss';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LoginButton from './LoginButton';
import NewsletterInput from './NewsletterInput';

import WpsLogoBLue from 'Assets/Images/wps-icon-blue.svg?react';
import Button from 'Components/Button/Button';
import FadeInOut from 'Components/FadeInOut/FadeInOut';
import { navMenuLinks, socialLinks } from 'Constants/Constants';
import useComponentVisible from 'Hooks/ComponentVisible';
import Portal from 'Hooks/Portal';
import { PublicRoutes } from 'routes';
import { sendEvent } from 'Utils/utilities';

const MenuDrawer = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
          sendEvent('Menu - Expand');
        }}
      >
        <div>{t('general.menu')}</div>
        <div className='hamburger'>
          <span />
          <span />
          <span />
        </div>
      </Button>

      <Portal id='wps-app-menu'>
        <FadeInOut isShown={isComponentVisible}>
          <div className='app-mask' />
        </FadeInOut>

        <nav
          className={clsx({
            'wps-drawer-menu': true,
            open: isComponentVisible,
          })}
          ref={ref}
        >
          <div className='wps-drawer-menu__heading'>
            <Link
              to={PublicRoutes.trending}
              onClick={() => {
                setIsComponentVisible(false);
                sendEvent('Menu - Access Home');
              }}
            >
              <WpsLogoBLue className='logo' />
            </Link>

            <button
              className='close closeIcon'
              onClick={() => {
                setIsComponentVisible(!isComponentVisible);
                sendEvent('Menu - Collapse');
              }}
            />
          </div>

          <div className='wps-drawer-menu__links'>
            <div onClick={() => setIsComponentVisible(false)}>
              <LoginButton />
            </div>
            {navMenuLinks.map((item) => {
              return (
                <Link
                  key={crypto.randomUUID()}
                  to={item.href}
                  onClick={() => {
                    setIsComponentVisible(false);
                    sendEvent(`Menu - Access ${t(item.title)}`);
                  }}
                >
                  {t(item.title)}
                </Link>
              );
            })}
          </div>

          <div className='wps-drawer-menu__social'>
            {socialLinks.map((item) => {
              return (
                <a
                  key={crypto.randomUUID()}
                  href={item.href}
                  title={item.title}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    setIsComponentVisible(false);
                    sendEvent(`Menu - Social ${t(item.title)}`);
                  }}
                >
                  {item.title}
                </a>
              );
            })}
          </div>
          <div onClick={() => setIsComponentVisible(false)}>
            <NewsletterInput className='wps-drawer-menu__newsletter' />
          </div>
        </nav>
      </Portal>
    </>
  );
};

export default MenuDrawer;
