import { useEffect } from 'react';

import { env } from 'Constants/Env';
import { useWpsDispatch } from 'Store';
import generalActions from 'Store/Actions/generalActions';

const sponsorUrls = {
  int: 'https://fsp-sponsor-service.int.uefa.com/v2/sponsors',
  pre: 'https://fsp-sponsor-service.pre.uefa.com/v2/sponsors',
  prod: 'https://fsp-sponsor-service.uefa.com/v2/sponsors',
};

export function useSponsors() {
  const dispatch = useWpsDispatch();

  useEffect(() => {
    const getSponsors = async () => {
      const url = new URL(sponsorUrls[env]);
      url.searchParams.set('competitionAndPhase', '18:TOURNAMENT'); // weuro
      url.searchParams.set('platform', 'DEFAULT');

      const res = await fetch(url.toString());
      const sponsors = await res.json();
      dispatch(generalActions.setSponsors(sponsors));
    };

    getSponsors();
  }, [dispatch]);
}
