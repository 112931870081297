import './Footer.scss';

import { useTranslation } from 'react-i18next';

import ComeAndPlay from 'Assets/Images/come-and-play.svg?react';
import Facebook from 'Assets/Images/Social/img-facebook-hover.svg?react';
import Giphy from 'Assets/Images/Social/img-giphy-hover.svg?react';
import Instagram from 'Assets/Images/Social/img-instagram-hover.svg?react';
import TikTok from 'Assets/Images/Social/img-tiktok-hover.svg?react';
import Twitter from 'Assets/Images/Social/img-twitter-hover.svg?react';
import Youtube from 'Assets/Images/Social/img-youtube-hover.svg?react';
import NewsletterInput from 'Components/NavBar/Components/NewsletterInput';
import { useWpsSelector } from 'Store';
import { sendEvent } from 'Utils/utilities';

const Footer = () => {
  const { t } = useTranslation();
  const sponsors = useWpsSelector((state) => state.general.sponsors);

  return (
    <div className='footer'>
      <div className='footer__social'>
        <div className='grid'>
          <div className='heading'>
            <ComeAndPlay />
          </div>
          <div className='social-list'>
            <div className='row'>
              <a
                onClick={() => sendEvent('Footer - Instagram')}
                className='social social--ig'
                href='https://www.instagram.com/weplaystrong'
                title='Instagram'
              >
                <Instagram />
                <span className='text'>Instagram</span>
              </a>
              <a
                onClick={() => sendEvent('Footer - YouTube')}
                className='social social--yt'
                href='https://www.youtube.com/weplaystrong'
                title='YouTube'
              >
                <Youtube />
                <span className='text'>YouTube</span>
              </a>
              <a
                onClick={() => sendEvent('Footer - TikTok')}
                className='social social--tt'
                href='https://www.tiktok.com/@weplaystrong'
                title='TikTok'
              >
                <TikTok />
                <span className='text'>TikTok</span>
              </a>
            </div>
            <div className='row'>
              <a
                onClick={() => sendEvent('Footer - Twitter')}
                className='social social--tw'
                href='https://www.twitter.com/weplaystrong_'
                title='Twitter'
              >
                <Twitter />
                <span className='text'>twitter</span>
              </a>

              <a
                onClick={() => sendEvent('Footer - Facebook')}
                className='social social--fb'
                href='https://www.facebook.com/weplaystrong'
                title='Facebook'
              >
                <Facebook />
                <span className='text'>facebook</span>
              </a>

              <a
                onClick={() => sendEvent('Footer - Giphy')}
                className='social social--gy'
                href='https://www.giphy.com/weplaystrong'
                title='Giphy'
              >
                <Giphy />
                <span className='text'>giphy</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='grid'>
        <div className='footer__related-links'>
          <div className='footer__partners'>
            {sponsors?.map((sponsor) => (
              <a href={sponsor.links['EN']} title={sponsor.name} key={sponsor.code}>
                <img src={sponsor.image} alt={sponsor.name} className='footer__sponsor-image' />
              </a>
            ))}
          </div>

          <div className='divider' />
        </div>

        <NewsletterInput />

        <div className='footer__disclaimers'>
          <div className='links'>
            <span>
              <a href='https://www.uefa.com/termsconditions/' title={t('general.termsAndConditions')}>
                {t('general.termsAndConditions')}
              </a>
            </span>
            <span>
              <a href='https://www.uefa.com/privacypolicy/' title={t('general.privacyPolicy')}>
                {t('general.privacyPolicy')}
              </a>
            </span>
            <span>
              <a href='https://www.uefa.com/cookiepolicy/' title={t('general.cookiePolicy')}>
                {t('general.cookiePolicy')}
              </a>
            </span>
            <span>
              <button id='ot-sdk-btn' className='ot-sdk-show-settings'>
                {t('general.cookieSettings')}
              </button>
            </span>
          </div>
          <div className='copyright'>{`© 1998-${new Date().getFullYear()} UEFA. All rights reserved`}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
