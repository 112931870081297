import { PkSpinner } from '@uefadigital/panenka-react';
import { Suspense, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';

import Footer from 'Components/Footer/Footer';
import NavBar from 'Components/NavBar/NavBar';
import { MAX_ARTICLES_DISPLAYED } from 'Constants/Constants';
import { useSponsors } from 'Hooks/Sponsors';
import { routes } from 'routes';
import searchApi from 'Services/SeachApi';
import { useWpsDispatch } from 'Store';
import generalActions from 'Store/Actions/generalActions';

const Main = () => {
  const dispatch = useWpsDispatch();

  useEffect(() => {
    searchApi.getTrendingNews().then((response) => {
      const articlesToDisplay = [...response];
      if (articlesToDisplay.length > MAX_ARTICLES_DISPLAYED) {
        articlesToDisplay.length = MAX_ARTICLES_DISPLAYED;
      }
      const hashtags = response.map((item) => item.hashtags);
      dispatch(generalActions.setTrendingArticles(articlesToDisplay));
      // From the multiple arrays of hashtags, create one array by
      // flattening the array of arrays,
      // removing hashtags that do not start with #,
      // removing duplicate hashtags,
      // and then, remove the # from all of the array elements
      const flattenHashtags = [].concat(...Object.values(hashtags));
      const filteredHashtags = flattenHashtags.filter((item) => item.charAt(0) === '#');
      const uniqueHashtags = [...new Set(filteredHashtags ?? [])];
      dispatch(
        generalActions.setHashtags({
          hashtags:
            uniqueHashtags.map((item) => {
              return item.substring(1);
            }) ?? [],
        }),
      );
    });
  }, [dispatch]);

  useSponsors();

  return (
    <HashRouter>
      <NavBar />
      <Suspense fallback={<PkSpinner className='pk-py--xl2' size={50} />}>{routes}</Suspense>
      <Footer />
    </HashRouter>
  );
};
export default Main;
