import { memo, useEffect } from 'react';

import Modal from 'Components/Modal/Modal';
import useIdp from 'Hooks/useIdp';

import './LoginModal.scss';

const LoginModal = ({ showModal, handleCloseModal }) => {
  const { idpInited, showLogin } = useIdp();

  useEffect(() => {
    if (!idpInited || !showModal) return;
    showLogin('login-target');
  }, [idpInited, showModal, showLogin]);

  return (
    <Modal showModal={showModal} className='login' animation='slide-in' handleCloseModal={handleCloseModal}>
      <div className='login-modal__text-container'>
        <div className='login-modal__title'>Why join us?</div>
        <div className='login-modal__text'>
          <div className='login-modal__image'></div>
          <p>
            Fancy meeting the <b>biggest names</b> in women’s football? Join us for the chance to{' '}
            <b>win once-in-a-lifetime prizes.</b>
          </p>
        </div>
        <div className='login-modal__text'>
          <div className='login-modal__image login-modal__image--second'></div>
          <p>
            <b>See which ladies are bossing it</b> across Europe – and <b>watch official highlights</b> from both
            women’s and men’s Champions Leagues on UEFA.tv.
          </p>
        </div>
      </div>
      <div className='login-modal__login-container'>
        <div id='login-target'></div>
      </div>
    </Modal>
  );
};

export default memo(LoginModal);
