import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import Caret from 'Assets/Images/caret-white.svg?react';
import WpsIconBlue from 'Assets/Images/wps-icon-blue.svg?react';
import Button from 'Components/Button/Button';
import useComponentVisible from 'Hooks/ComponentVisible';
import { PublicRoutes } from 'routes';
import { useWpsSelector } from 'Store';
import { sendEvent } from 'Utils/utilities';

import './Hashtags.scss';

const Trending = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();

  const hashtags = useWpsSelector((state) => state.general.hashtags);
  const selectedHashtag = useWpsSelector((state) => state.navbar.selectedHashtag);

  const [scrollIndicator, setScrollIndicator] = useState(true);

  const handleNavScroll = () => {
    if (scrollIndicator) {
      setScrollIndicator(false);
    }
  };

  const { sticky } = props;

  return (
    <div
      className={clsx({
        hashtags: true,
        sticky: sticky,
      })}
    >
      <div className='grid'>
        <Link
          to={PublicRoutes.trending}
          className='logo'
          onClick={() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }}
        >
          <WpsIconBlue className='wps-icon-blue' />
        </Link>
        <nav
          className={clsx({
            hashtags__trending: true,
            'hashtags__trending--faded': isComponentVisible,
          })}
          onScroll={handleNavScroll}
        >
          <span className='title'>{t('general.hastagsTitle')}:</span>

          {hashtags.map((item) => {
            return (
              <Link
                onClick={() => sendEvent(`Hashtag - Access #${item}`)}
                to={`${PublicRoutes.hashtagView}/${item}`}
                key={crypto.randomUUID()}
                className={clsx({
                  hashtag: true,
                  active: item === selectedHashtag,
                })}
              >
                #{item}
              </Link>
            );
          })}
        </nav>
        {scrollIndicator && (
          <span className='hashtags__scroll-indicator'>
            <Caret />
          </span>
        )}
        <span
          ref={ref}
          className={clsx({
            hashtags__dropdown: true,
            active: isComponentVisible,
          })}
        >
          <Button
            className='heading'
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
              sendEvent(`Hashtag - More ${isComponentVisible ? 'Collapse' : 'Expand'}`);
            }}
          >
            {t('general.more')}
            <span
              className={clsx({
                arrow: true,
                active: isComponentVisible,
              })}
            >
              <span></span>
              <span></span>
            </span>
          </Button>
          {isComponentVisible && (
            <nav>
              <ul className='content'>
                {hashtags.map((item) => {
                  return (
                    <li key={crypto.randomUUID()}>
                      <Link
                        to={`${PublicRoutes.hashtagView}/${item}`}
                        onClick={() => {
                          setIsComponentVisible(false);
                          sendEvent(`Hashtag - More - Access #${item}`);
                        }}
                        className={clsx({
                          hashtag: true,
                          active: item === selectedHashtag,
                        })}
                      >
                        #{item}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          )}
        </span>
      </div>
    </div>
  );
};

export default withRouter(Trending);
