import clsx from 'clsx';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Hashtags from './Components/Hashtags';
import MenuDrawer from './Components/MenuDrawer';

import WpsLogoLong from 'Assets/Images/wps-logo-long.svg?react';
import useWindowSize from 'Hooks/ResizeHandler';
import { PublicRoutes } from 'routes';
import { useWpsDispatch, useWpsSelector } from 'Store';
import navbarActions from 'Store/Actions/navbarActions';
import { screenTablet, screenDesktopMd, screenPhoneLg } from 'Utils/screen';
import { processHashtags, sendEvent } from 'Utils/utilities';

import './NavBar.scss';

const NavBar = () => {
  const { t } = useTranslation();
  const [sticky, setSticky] = useState(false);
  const lastScrollY = useRef(window.scrollY ?? 0);

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    setSticky(offset > 200 && offset < lastScrollY.current);
    lastScrollY.current = offset;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const dispatch = useWpsDispatch();
  const mainMedia = useWpsSelector((state) => state.navbar.mainMedia);
  const articlePreview = useWpsSelector((state) => state.general.articlePreview);
  const navTitle = useWpsSelector((state) => state.navbar.navTitle);
  const navClass = useWpsSelector((state) => state.navbar.navClass);
  const [screenWidth] = useWindowSize();

  const setNavbarStyle = () => {
    const navHeightMobile = '200px';
    const navHeightTablet = '412px';
    const navHeightMobilePicture = '510px';
    const navHeightTabletPicture = '734px';
    const linearGradients = `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 70%),
    linear-gradient(60deg, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 80%)`;
    if (!mainMedia) {
      return {
        minHeight: screenWidth >= screenTablet ? navHeightTablet : navHeightMobile,
      };
    }
    if (screenWidth >= screenDesktopMd) {
      return {
        backgroundImage: `${linearGradients},
        url(${mainMedia?.image.ultraWide.ultrawide})`,
        minHeight: navHeightTabletPicture,
      };
    }
    if (screenWidth >= screenTablet) {
      return {
        backgroundImage: `${linearGradients},
        url(${mainMedia?.image.wide.w1})`,
        minHeight: navHeightTabletPicture,
      };
    }
    if (screenWidth >= screenPhoneLg) {
      return {
        backgroundImage: `${linearGradients},
        url(${mainMedia?.image.square.s1})`,
        minHeight: navHeightTabletPicture,
      };
    }
    return {
      backgroundImage: `${linearGradients},
      url(${mainMedia?.image.portrait.p1})`,
      minHeight: navHeightMobilePicture,
    };
  };

  return (
    <div
      className={clsx({
        navbar: true,
        sticky: sticky,
        [`navbar--${navClass}`]: navClass,
      })}
      style={setNavbarStyle()}
    >
      <div className='logo-and-menu'>
        <Link
          to={PublicRoutes.trending}
          className='logo'
          onClick={() => {
            dispatch(navbarActions.resetNavBar());
            sendEvent('Logo - Access Home');
          }}
        >
          <WpsLogoLong />
        </Link>
        <MenuDrawer />
      </div>
      <Hashtags sticky={sticky} />
      <div className='navbar-page-heading'>
        {navTitle && (
          <div className='navbar-page-heading__hashtag-title'>
            <span>{navTitle}</span>
          </div>
        )}

        {!navTitle && articlePreview && (
          <>
            <div className='navbar-page-heading__hashtag'>{processHashtags(articlePreview)}</div>
            <div className='navbar-page-heading__title'>
              <span title={articlePreview.title}>{articlePreview.title}</span>
            </div>
            <div className='navbar-page-heading__action'>
              <Link to={`${PublicRoutes.articleView}/${articlePreview.id}`}>{t('general.more')}</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
