import { http } from './Instance';

import { baseUrl } from 'Constants/Env';
import { mapEditorialContent } from 'Utils/utilities';

const searchApiUrlCached = '/api/cachedsearch';
const searchApiUrl = '/api';
const appSection = `param.attributes.main.section=Together%20We%20Play%20Strong&type=article&param.attributes.main.kind!=supporters`;
const appSectionWithSupporters = `param.attributes.main.section=Together%20We%20Play%20Strong&type=article`;
const aggregatorNode = 'aggregator=lightnodejson';
const aggregatorPage = 'aggregator=lightpagejson';
const sorting = 'sorting=-attributes.lastPublicationDate';

export const searchApi = {
  getTrendingNews: async () => {
    const response = await http.get(
      `${baseUrl}${searchApiUrlCached}/build?${appSection}&${aggregatorNode}&${sorting}`,
      null,
    );
    const processedResponse = response.result.map((item) => {
      return mapEditorialContent(item.nodeData) ?? {};
    });
    return processedResponse;
  },

  getNewsByKind: async (kind) => {
    const response = await http.get(
      `${baseUrl}${searchApiUrlCached}/build?${appSectionWithSupporters}&param.attributes.main.kind=${kind}&${aggregatorNode}&${sorting}`,
      null,
    );
    const processedResponse = response.result.map((item) => {
      return mapEditorialContent(item.nodeData) ?? {};
    });
    return processedResponse;
  },

  getNewsByHashtag: async (hashtag) => {
    const response = await http.get(
      `${baseUrl}${searchApiUrlCached}/build?${appSection}&param.attributes.hashtags=%23${hashtag}&${aggregatorNode}&${sorting}`,
      null,
    );
    const processedResponse = response.result.map((item) => {
      return mapEditorialContent(item.nodeData) ?? {};
    });
    return processedResponse;
  },

  getTrendingArticle: async (articleId) => {
    const response = await http.get(`${baseUrl}${searchApiUrl}/pages/${articleId}?${aggregatorPage}`, null);
    return mapEditorialContent(response?.model?.data, response?.model?.nodes) ?? {};
  },

  getAboutPageArticle: async () => {
    const response = await http.get(`${baseUrl}${searchApiUrl}/pages/?${aggregatorPage}&url=TWPS.ABOUT/EN`, null);
    return mapEditorialContent(response?.model?.data, response?.model?.nodes) ?? {};
  },

  getCancelController: () => {
    return http.generateCancelController();
  },

  isRequestCanceled: (e) => {
    return http.isRequestCanceled(e);
  },
};

export default searchApi;
